import React, { useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { FaWallet } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { BsQuestion } from "react-icons/bs";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getWalletBalance,
  todoListStatus,
  verifyTransactionPinCode,
} from "../../../store/actions";
import { authService } from "../../../services/authService";
import toast from "react-hot-toast";
import IsLoadingFour from "../../IsloadingFour";
import { setIsSubmiting } from "../../../store/slices/modalSlice";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

function SufficientBallancePopover({
  cartAmount,
  paymentProceed,
  onClose,
  handleShowingPinCreate,
}) {
  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(false);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedUserBalance, setFetchedUserBalance] = useState();
  const { isSubmiting } = useSelector((state) => state.modal);
  const [fetchedStatuses, setfetchedStatuses] = useState("");

  const { walletBalance } = useSelector((state) => state.user);
  const { todoListStatusData } = useSelector((state) => state.masterData);
  const verification_code = authService.getCurrentUserVerificationCode();
  const userId = authService.getCurrentUserID();
  const customerId = authService.getCurrentUserCustomerId();

  // useEffect(() => {
  //   if ()
  // }, [isSubmiting])
  useEffect(() => {
    if (!todoListStatusData) {
      const data = {
        sql_ref_no: "20",
        user_id: userId.toString(),
        verification_code: verification_code?.toString(),
        option: "1",
      };

      dispatch(todoListStatus(data));
    }

    if (todoListStatusData) setfetchedStatuses(todoListStatusData);
  }, [dispatch, todoListStatusData, verification_code, userId]);

  useEffect(() => {
    if (!walletBalance) {
      const data = {
        sql_ref_no: "31",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        option: "1",
      };
      dispatch(getWalletBalance(data))
        .unwrap()
        .catch((err) => {
          return err;
        });
    }

    if (walletBalance) setFetchedUserBalance(walletBalance);
  }, [dispatch, userId, customerId, verification_code, walletBalance]);

  const handleSubmit = () => {
    try {
      const toastId = toast.loading("Checking pin.");
      // setIsLoading(true);
      dispatch(setIsSubmiting(true));

      const verifyData = {
        sql_ref_no: "41",
        user_id: userId?.toString(),
        customer_id: customerId?.toString(),
        verification_code,
        pin_number: otp,
        option: "1",
      };

      dispatch(verifyTransactionPinCode(verifyData))
        .unwrap()
        .then((res) => {
          if (res && res.message_id !== 1) {
            dispatch(setIsSubmiting(false));
            return toast.error(
              "Pin Incorrect...Please create or reset your pin!",
              { id: toastId }
            );
          }

          paymentProceed();

          return toast.success("Pin Correct...", { id: toastId });
        })
        .catch((err) => {
          dispatch(setIsSubmiting(false));
          toast.error("Pin Incorrect...Please create or reset your pin!", {
            id: toastId,
          });
        });
    } catch (err) {
      return err;
    }
  };

  return (
    <div className="mx-auto w-full max-w-md rounded-2xl bg-[#F9F9F9] px-3 py-3 text-gray-500 xsm:px-6 xsm:py-4">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold text-gray-600">
          Grocedy Transaction PIN
        </div>
        <button
          onClick={() => {
            onClose();
            dispatch(setIsSubmiting(false));
          }}
          className="rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
        >
          <IoClose className="h-5 w-5 text-gravel-500" />
        </button>
      </div>
      <div className="pb-4">
        <div className="mt-6 flex items-center justify-between rounded-lg border-[0.5px] border-gray-300 bg-[#E9F8CC] p-3 sm:p-4">
          <div className="flex w-full items-center justify-between">
            <div>
              <div className="flex items-center space-x-2 text-gray-600">
                <div>
                  <FaWallet className="h-5 w-5" />
                </div>
                <div className="">Total Balance</div>
              </div>
              <div>
                {enabled ? (
                  <h2 className="pt-1 text-xl font-bold text-gravel-600 sm:text-2xl">
                    {fetchedUserBalance && fetchedUserBalance?.available_balance
                      ? `₦ ${Number(
                          fetchedUserBalance?.available_balance
                        ).toLocaleString()}`
                      : "₦ 0.00"}
                  </h2>
                ) : (
                  <h2 className="pt-1 text-xl font-bold text-gravel-600 sm:text-2xl">
                    ********
                  </h2>
                )}
              </div>
            </div>
            <div>
              <div>
                {enabled ? (
                  <div onClick={() => setEnabled(false)}>
                    <AiFillEyeInvisible className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                  </div>
                ) : (
                  <div onClick={() => setEnabled(true)}>
                    <AiFillEye className="h-6 w-6 cursor-pointer text-olivine-500 active:text-olivine-700" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 rounded-md bg-[#F1F1F1] px-3 py-2 sm:px-4">
          <h3 className="text-xs">You are paying:</h3>
          <h2 className="text-xl font-bold text-gray-600 sm:text-lg">
            {cartAmount ? `₦ ${Number(cartAmount).toLocaleString()}` : "₦ 0.00"}
          </h2>
          {/* <p className="text-xs">Plan name and add-ons</p> */}
        </div>
        <div className="mt-1">
          {fetchedStatuses?.transaction_pin !== "True" ? (
            <label className="pl-1 text-start text-xs font-semibold text-gray-600">
              Please set-up your Grocedy transaction PIN to continue
            </label>
          ) : (
            <label className="pl-1 text-start text-xs font-semibold text-gray-600">
              Please input your Grocedy transaction PIN
            </label>
          )}

          {fetchedStatuses?.transaction_pin !== "True" ? (
            <div className="mt-5 flex flex-row items-center space-x-1 pb-3 pl-1">
              <p
                onClick={() => handleShowingPinCreate()}
                className="cursor-pointer text-xs font-medium text-rose-600 hover:underline hover:underline-offset-2"
              >
                Set-up Grocedy Transaction pin
              </p>
              <Tippy
                content={
                  <div
                    className="max-w-sm rounded-md bg-neutral-600/95 px-3 py-2 text-xs text-slate-200"
                    interactive={true}
                    interactiveBorder={20}
                    delay={100}
                  >
                    <p>Set-up Grocedy Transaction pin </p>
                  </div>
                }
              >
                <div className="mt-1 rounded-full bg-olivine-600">
                  <BsQuestion className="h-3 w-3 text-white" />
                </div>
              </Tippy>
            </div>
          ) : null}

          {fetchedStatuses?.transaction_pin !== "True" ? null : (
            <OtpInput
              inputStyle={{
                margin: 3,
                height: 30,
                width: 35,
                borderRadius: 4,
                borderColor: "#BDBDBD",
                outline: "none",
                border: "1px solid #BDBDBD",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderInput={(props) => <input {...props} />}
            />
          )}
        </div>
        <div className="mt-6 flex items-center justify-between">
          <button
            onClick={() => {
              onClose();
              dispatch(setIsSubmiting(false));
            }}
            className="transform cursor-pointer rounded-md border-[1px] border-olivine-500 bg-white px-6 py-2.5 text-sm font-semibold text-olivine-500 transition-all  duration-200 focus:outline-none focus:ring-[2px] focus:ring-olivine-500 focus:ring-opacity-60 focus:ring-offset-1 active:bg-olivine-100 xsm:px-8"
          >
            Back
          </button>
          {!isSubmiting ? (
            <button
              disabled={otp?.length === 4 ? false : true}
              onClick={(e) => handleSubmit(e)}
              className="rounded-md bg-olivine-500 px-6 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              Confirm Payment
            </button>
          ) : (
            <button
              disabled={true}
              className="cursor-not-allowed rounded-md bg-olivine-500 px-8 py-2 text-sm font-semibold  text-white transition duration-300 hover:bg-olivine-600 disabled:opacity-30 disabled:hover:bg-olivine-500 sm:px-10"
            >
              <IsLoadingFour />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SufficientBallancePopover;
